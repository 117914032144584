<template>
    <div>
        <!-- Map Area Background color Seat Color Dialog -->
        <v-dialog v-model="planDialog" width="580" @input="closeEdit" class="plan-setting-dialog" persistent>
            <v-form ref="form" v-model="valid" class="plan-setting-form">
                <v-card-actions class="plan-top-action">
                    <v-row>
                        <v-col md="9">
                            <div class="plan-cf-action-heading">Seat Map Settings</div>
                        </v-col>
                            <v-col md="3" style="text-align:right;">
                                <v-btn
                                color="darken-1"
                                class="ma-2 text"
                                text
                                @click="closeEdit"
                                >X</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card>
                <v-card-text class="pos-cf-body">
                    <v-container>
                        <v-row class="mb-5">
                            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                                <v-text-field
                                    hide-details="auto"
                                    label="Seat Map Name"
                                    outlined
                                    background-color="#fff"
                                    placeholder="Seat Map name"
                                    v-model="plan.map_name"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mb-5">
                            <!-- <v-col cols="12" sm="6" md="6" class="pt-0 pb-0 ">
                                <label>Background Image</label>
                                <image-upload
                                    @upload="
                                        (data) => {
                                        plan.map_image = data;
                                        }
                                    "
                                    @result="
                                        (data) => {
                                            bgImageUrl = data;
                                        }
                                    "
                                    text="Click to upload background image"
                                    @remove="
                                        (data) => {
                                        plan.map_image = null;
                                        bgImageUrl = null;
                                    }
                                    "
                                    ></image-upload>
                            </v-col> -->
                             <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                                <label>Background Color</label>
                                <v-card  
                                    class="rounded-lg"
                                    @click="dialogBg = true"
                                    elevation="0"
                                    :color="plan.map_bg_color"
                                    style="width: 100%; height: 30px; border:1px solid black;">
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                                <label>Grid Line Color</label>
                                <v-card  
                                    class="rounded-lg"
                                    @click="dialogSc = true"
                                    elevation="0"
                                    :color="plan.map_grid_line_color"
                                    style="width: 100%; height: 30px; border:1px solid black;">
                                    </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">
                                <v-select
                                v-model="plan.map_area"
                                :items="mapAreaList"
                                item-value="id"
                                outlined
                                :menu-props="{ bottom: true, offsetY: true }"
                                background-color="#fff"
                                item-text="name"
                                :label="`Seat Map Area Size (px)*`"
                                required
                                ></v-select>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">
                                <v-select
                                v-model="plan.map_seat_area"
                                :items="seatSizeList"
                                item-value="id"
                                outlined
                                :menu-props="{ bottom: true, offsetY: true }"
                                background-color="#fff"
                                item-text="name"
                                :label="`Seat Size (px)*`"
                                required
                                ></v-select>
                            </v-col>
                        </v-row>
                         <v-row>
                            <v-col cols="6" sm="12" md="6" class="pt-0 pb-0">
                                <v-select
                                v-model="plan.status_id"
                                :items="[ 
                                            { id:1, name:'Active' },
                                            { id: 2, name:'Inactive' }
                                        ]"
                                item-value="id"
                                outlined
                                :menu-props="{ bottom: true, offsetY: true }"
                                background-color="#fff"
                                item-text="name"
                                :label="`Status*`"
                                required
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pos-cf-bottom-action">
                    <v-btn
                        text
                        class="ma-2"
                        @click="closeEdit"
                        >Close</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        color=" darken-1"
                        class="ma-2 white--text blue-color"
                        @click="save"
                        v-if="checkWritePermission($modules.space.management.slug)"
                        text
                    >Save Changes</v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- End Map Area Background color Seat Color Dialog -->
        <v-dialog v-model="dialogBg" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Select Background Color </v-card-title>
                <v-card-text>
                    <v-color-picker
                        v-model="plan.map_bg_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogDark = false">
                        Cancel
                    </v-btn> -->
                    <v-btn
                        color="green darken-1"
                        outlined
                        @click="dialogBg = false"
                    >
                        Done
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Set Seat Color Dialog -->
        <v-dialog v-model="dialogSc" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Select Seat Color </v-card-title>
                <v-card-text>
                <v-color-picker
                    v-model="plan.map_grid_line_color"
                    mode="hexa"
                ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    outlined
                    @click="dialogSc= false"
                >
                    Done
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Set Seat Color Dialog -->
    </div>
</template>

<script>
export default {
    props: {
        planDialog: { type: Boolean, default: false },
        currentMapPlanId: { type: Number, default: null },
        facilityId: { type:Number,default: null}
    },
    watch: {
        currentMapPlanId: {
            handler(currentMapPlanId) {
                if (currentMapPlanId) {
                    this.getMapPlan(currentMapPlanId);
                }
            },
        },
    },
    data() {
        return {
            dialogBg: false,
            dialogSc: false,
            valid: true,
            bgImageUrl: null,
            mapAreaList: [
                {
                    id: '600:400',
                    name: '600 x 400'
                },
                {
                    id: '800:600',
                    name: '800 x 600'
                },
            ],
            seatSizeList: [
                {
                    id: '20:20',
                    name: '20 x 20'
                },
                {
                    id: '30:30',
                    name: '30 x 30'
                },
                {
                    id: '40:40',
                    name: '40 x 40'
                },
            ],
            plan: {
                map_name: "",
                map_bg_color: "#D3D3D3FF",
                map_grid_line_color: "#fff",
                map_area: "800:600",
                map_width: 800,
                map_height: 600,
                map_grid_wh: 10,
                map_seat_area: "20:20",
                map_seat_wh: 20,
                map_image: "",
                status_id: 1,
            },
        };
    },
    methods: {
        closeEdit() {
            this.$emit('close');
        },
        getMapPlan(id) {
            this.showLoader("Loading");
            this.$http.get(`venues/seat-maps/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data && response.data.data) {
                        this.plan = response.data.data;
                        if (response.data.data.map_image) {
                            this.bgImageUrl = response.data.data.map_image;
                        }
                        
                    }else{
                        this.showError('Data not found');
                    }
                    this.hideLoader();
                }
            })
            .catch((error) => {
                console.log(error);
                this.hideLoader();
            });  
        },
        save() {
            if (!this.$refs.form.validate()) {
                this.showError("Fill all required fields");
                return;
            }
            if(!this.facilityId){
                this.showError("Facility Id is required");
                return;
            }
            this.showLoader();
            let formData = new FormData();
            Object.keys(this.plan).forEach((key) => {
                let value = this.plan[key];
                if (value != null) {
                    formData.append(key, value);
                }
            });

            const map_area_sizeArr = this.plan.map_area.split(':');
            const seat_sizeArr =  this.plan.map_seat_area.split(':');

            formData.append('map_width', parseInt(map_area_sizeArr[0]));
            formData.append('map_height', parseInt(map_area_sizeArr[1]));
            formData.append('map_seat_wh', parseInt(seat_sizeArr[0]));
            formData.append('facility_id', this.facilityId);

            this.$http.post("venues/seat-maps/", formData).then((response) => {
                if (response.status == 200 && response.data.status == true) {
                    this.showSuccess("Seat Map Updated Successfully.");
                    this.hideLoader();
                    this.$emit('save', response.data)
                }
            }).catch((error) => {
                this.errorChecker(error);
            });
        }
    },
};
</script>
<style scoped>

form.v-form.plan-setting-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.plan-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}
</style>