<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right">
        <v-btn
          v-if="checkWritePermission($modules.space.management.slug)"
          class="teal-color"
          @click="addNewSeatPlan"
          dark
          large
        >
          Create Seat Plan
          <v-icon right dark-blue>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="12">
        <v-container>
          <v-row>
              <v-col cols="12" lg="4" md="4" sm="6" v-for="(plan,index) in allMapPlans" :key="index">
                <SpaceMapWidget  v-bind="plan"  @edit="editPlan" @delete="deletePlan"/>
              </v-col>
          </v-row>
          <v-row>
            <v-col v-if="allMapPlans.length > 0">
                <v-pagination
                    v-if="totalPages > 0"
                    v-model="page"
                    :length="totalPages"
                ></v-pagination>
            </v-col>
             <v-col class="text-center" v-else>No Data Found</v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <MapPlanForm :planDialog="planDialog" :facilityId="this.facilityId" :currentMapPlanId="currentMapPlanId" @close="planDialog = false;currentMapPlanId=null" @save="savePlan" />
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>

import MapPlanForm from './MapPlanForm.vue';
import SpaceMapWidget from './SpaceMapWidget.vue';
import ConfirmModel from "@/components/Confirmation/ConfirmModel";

export default {
  components: { MapPlanForm,SpaceMapWidget,ConfirmModel },
  data() {
    return {
      planDialog: false,
      currentMapPlanId: null,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      allMapPlans:[],
      page: 1,
      perPage: 12,
      totalPages: 1,
      facilityId: null,

    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.getRouteParams();
    this.getAllMaps();
  },
  methods: {
     getRouteParams() {
        if (this.$route.params.data) {
          let fId = JSON.parse(atob(this.$route.params.data));
          if (fId) {
            this.facilityId = fId;
            this.getAllMaps();
          }else{
            this.facilityId = null;
          }
        } else {
            this.$router.push({
                name: "Spaces",
            });
        }
    },
    getAllMaps() {
      if (!this.facilityId) {
        this.showError("Facility Id is required");
        this.$router.push({
            name: "Spaces",
        });
      }
      this.showLoader("Loading");
      this.$http.get(`venues/seat-maps?facility_id=${this.facilityId}`)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          if(data && data.length){
              this.allMapPlans = data;
              this.totalPages = response.data.total_pages;
          }else{
              this.allMapPlans = [];
              this.totalPages = 1;
          }
          this.hideLoader();
        }
      })
      .catch((error) => {
          console.log(error);
          this.hideLoader();
      });
    },
    addNewSeatPlan() {
      this.planDialog = true;
    },
    closeEdit() {
      this.planDialog = false;
    },
   
    confirmActions(data) {
      console.log(data);
      // if (data.type == "delete_section") {
      // } 
      // this.confirmModel.id = null;
    },
    
    savePlan(data = null) {
      this.planDialog = false;
      this.currentMapPlanId = null;
      console.log(data);
      this.getAllMaps();
    },
    editPlan(id) {
      if (id) {
        this.currentMapPlanId = id;
        this.planDialog = true;
      }
    },
    deletePlan() {},
  },
};
</script>