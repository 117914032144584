<template
  ><v-card color="#3e4c55" class="mt-2 mr-5 ml-2">
    <v-card-text>
      <v-row>
        <v-col md="5">
          <v-avatar class="profile" color="grey" size="120" tile>
            <view-image :image="image_path" defaultImage="user"></view-image>
          </v-avatar>
        </v-col>
        <v-col md="7">
          <v-list-item style="margin-top: -15px" color="rgba(0, 0, 0, .4)" dark>
            <v-list-item-content>
              <v-list-item-title class="title">{{
                map_name
              }}</v-list-item-title>
              <!-- <v-list-item-subtitle>{{ type }}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
          <v-card-subtitle style="margin-top: -20px" class="orange--text text--lighten-5" >
            <span class="caption">Map Area (px):- {{ map_width }} x {{ map_height }}</span><br />
            <span class="caption">Map Seat Area (px):- {{ map_seat_wh }} x {{ map_seat_wh }}</span><br />
            <span class="caption">Map Grid Size (px):- {{ map_grid_wh }} x {{ map_grid_wh }}</span><br />
           <br />
            <span>
              Status:
              <v-btn
                dense
                width="32%"
                x-small
                :color="status_id == 1 ? 'green' : 'red'"
                dark
                >{{ status_id == 1 ? "Active" : "Inactive" }}</v-btn
              >
            </span>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card-text>
    <v-bottom-navigation>
      <v-btn 
        v-if="checkWritePermission($modules.space.management.slug)"  
        text @click="$emit('edit', id)">
        <span>Edit</span>
        <v-icon medium>mdi-pencil</v-icon>
      </v-btn>
       <v-btn @click="viewConfig(id,'config')" v-if="checkReadPermission($modules.space.management.slug)" >
        <span>Config</span>
        <v-icon> mdi-cog-outline </v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-card>
</template>
<script>
export default {
  props: {
    id: { type: Number, default: 0 },
    map_name: { type: String, default: "" },
    map_seat_wh: { type: Number, default: 0 },
    map_width: { type: Number, default: 0 },
    map_height: { type: Number, default: 0 },
    map_grid_wh: { type: Number, default: 0 },
    type: { type: String, default: "" },
    status_id: { type: Number, default: 1 },
    image_path: {
      type: String,
      default: null,
    },
  },
  methods: {
    viewPartner(id) {
      this.$router.push({
        name: "B2bPartnersView",
        params: { data: btoa(id) },
      });
    },
    viewConfig(id, type) {
      if (type == 'config') {
        this.$router.push({
          name: "SeatMapConfig",
          params: { data: btoa(id) },
        });
      }
      
    },
  },
  mounted() {
    // this.loadPartners();
  },
  computed: {},
};
</script>
<style scoped></style>
